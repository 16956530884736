<template>
  <v-row>
    <v-btn
      color="RMwhite"
      class="pa-0 ma-1"
      min-height="40"
      min-width="40"
      @click="zoomIn()"
    >
      <SvgIcon
        :title="'Zoom in'"
        :path="zoomInSvg"
      />
    </v-btn>
    <v-btn
      color="RMwhite"
      class="pa-0 ma-1"
      min-height="40"
      min-width="40"
      @click="zoomOut()"
    >
      <SvgIcon
        :rotate="'90'"
        :title="'Zoom out'"
        :path="zoomOutSvg"
      />
    </v-btn>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex';
import SvgIcon from '@/components/utils/SvgIcon.vue';
import { svgZoomIn, svgZoomOut } from '@/svg.js';

export default {
  components: {
    SvgIcon
  },
  data() {
    return {
      zoomInSvg: svgZoomIn,
      zoomOutSvg: svgZoomOut
    };
  },
  methods: {
    ...mapActions(['zoomIn', 'zoomOut'])
  }
};
</script>
