import moment from 'moment-business-days';

//generate nested array from linear Object array. 1st check parent/child id match with filter
//2nd pass item shallow copy + children array to new Obj array with map
export const nestHierarchyItems = (linearObjectArray, node_id = '') => {
  return linearObjectArray
    .sort((a, b) => a.sort_id - b.sort_id)
    .filter(item => item.father_id == node_id)
    .map(item => ({
      ...item,
      children: nestHierarchyItems(linearObjectArray, item.node_id)
    }));
};

export const getFormattedCurrentDate = (newDate) => {
  let month = new String(newDate.getMonth() + 1);
  let day = new String(newDate.getDate());
  const year = newDate.getFullYear();
  const time = ('0' + newDate.getHours()).slice(-2) + ':' + ('0' + newDate.getMinutes()).slice(-2);

  if (month.length < 2) {
    month = '0' + month;
  }

  if (day.length < 2) {
    day = '0' + day;
  }

  const date = `${year}-${month}-${day} ${time}`;

  return date;
};

export const getFormattedDate = (newDate, bool, seconds) => {
  let month = new String(newDate.getMonth() + 1);
  let day = new String(newDate.getDate());
  const year = newDate.getFullYear();
  const time = seconds ?
    ('0' + newDate.getHours()).slice(-2) + ':' + ('0' + newDate.getMinutes()).slice(-2) + ':' + ('0' + newDate.getSeconds()).slice(-2) :
    ('0' + newDate.getHours()).slice(-2) + ':' + ('0' + newDate.getMinutes()).slice(-2);

  if (month.length < 2) {
    month = '0' + month;
  }

  if (day.length < 2) {
    day = '0' + day;
  }

  const date = `${day}-${month}-${year}${bool ? (' ' + time) : ''}`;

  return date;
};

export const dateUnit = (animationStepDateType) => {
  let type = '';

  switch (animationStepDateType) {
    case 'dynamic_months':
      type = 'm';
      break;
    case 'dynamic_minutes':
      type = 'm';
      break;
    case 'dynamic_hours':
      type = 'h';
      break;
    default:
      type = 'd';
      break;
  }

  return type;
};

export const updatedDateWithTimeStep = (stepValue, stepDateType, route, time, UTC) => {
  const isUTC = UTC || false;
  const step = parseInt(stepValue);
  const setTime = time || new Date();
  let newTime = new Date(setTime);

  switch (stepDateType) {
    case 'dynamic_minutes':
      if (route === 'Real-Time' || route === 'remove_step') {
        newTime = newTime.setTime(newTime.getTime() - step * 60000);
      } else {
        newTime = newTime.setTime(newTime.getTime() + step * 60000);
      }

      break;
    case 'dynamic_hours':
      if (route === 'Real-Time' || route === 'remove_step') {
        newTime = newTime.setTime(newTime.getTime() - step * 3600000);
      } else {
        newTime = newTime.setTime(newTime.getTime() + step * 3600000);
      }

      break;
    case 'dynamic_months':
      if (route === 'Real-Time' || route === 'remove_step') {
        newTime = newTime.setUTCMonth(newTime.getUTCMonth() - step);
      } else {
        newTime = newTime.setUTCMonth(newTime.getUTCMonth() + step);
      }

      break;
    case 'ticketing_months':
      if (route === 'remove_step') {
        newTime = newTime.setMonth(newTime.getMonth() - step);
      } else {
        newTime = newTime.setMonth(newTime.getMonth() + step);
      }

      break;
    default:
      if (route === 'Real-Time' || route === 'remove_step') {
        newTime = isUTC
          ? newTime.setUTCDate(newTime.getDate() - step)
          : newTime.setDate(newTime.getDate() - step);
      } else {
        newTime = isUTC
          ? newTime.setUTCDate(newTime.getDate() + step)
          : newTime.setDate(newTime.getDate() + step);
      }

      break;
  }

  return newTime;
};

export const timeToMilliseconds = (stepValue, stepDateType) => {
  const step = parseInt(stepValue || 0);
  const type = stepDateType.split('_')[1];

  return moment.duration(step, type).asMilliseconds();
};

export const GET = 'GET';

export const SET = 'SET';

export const DEL = 'REMOVE';

export const localStorageOperations = (action, key, value) => {
  if (action === 'GET') {
    return JSON.parse(localStorage.getItem(key));
  }

  if (action === 'SET') {
    localStorage.setItem(key, JSON.stringify(value));
  }

  if (action === 'REMOVE') {
    if (Array.isArray(key)) {
      key.forEach(item => localStorage.removeItem(item));
    } else {
      localStorage.removeItem(key);
    }
  }
};
