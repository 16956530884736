// -------------------- ADMIN ----------------------

export const newAdmin = /* GraphQL */ `
  mutation newAdmin(
    $username: String!
    $_name: String!
    $surname: String!
    $email: String!
  ) {
    createAdminUser(
      input: {
        username: $username
        name: $_name
        surname: $surname
        email: $email
      }
    ) {
      username
      _name
      surname
      email
    }
  }
`;

export const deleteAdminUser = /* GraphQL */ `
  mutation deleteAdminUser($username: String!) {
    deleteAdminUser(input: { username: $username }) {
      username
      _state
    }
  }
`;

// -------------------- CUSTOMER ----------------------

export const newCustomer = /* GraphQL */ `
  mutation newCustomer(
    $crm_id: String!
    $ragione_sociale: String!
    $c_description: String!
  ) {
    createCustomer(
      input: {
        crm_id: $crm_id
        ragione_sociale: $ragione_sociale
        c_description: $c_description
      }
    ) {
      crm_id
      ragione_sociale
      c_description
    }
  }
`;

export const updateCustomer = /* GraphQL */ `
  mutation updateCustomer(
    $crm_id: String!
    $ragione_sociale: String!
    $c_description: String!
  ) {
    updateCustomer(
      input: {
        crm_id: $crm_id
        ragione_sociale: $ragione_sociale
        c_description: $c_description
      }
    ) {
      crm_id
      ragione_sociale
      c_description
    }
  }
`;

export const deleteCustomer = /* GraphQL */ `
  mutation deleteCustomer($crm_id: String!) {
    deleteCustomer(input: { crm_id: $crm_id }) {
      crm_id
      ragione_sociale
      c_description
    }
  }
`;

// -------------------- APP USER ---------------------

export const updateAppUser = /* GraphQL */ `
  mutation updateAppUser(
    $app_user_id: String!
    $name: String!
    $surname: String!
    $email: String!
    $phone: String
    $language: String
    $user_map_style: String
  ) {
    updateAppUser(
      input: {
        app_user_id: $app_user_id
        name: $name
        surname: $surname
        email: $email
        phone: $phone
        language: $language
        user_map_style: $user_map_style
      }
    ) {
      service_id
    }
  }
`;

// -------------------- TECH USER ------------------------

export const newTechUser = /* GraphQL */ `
  mutation newTechUser(
    $username: String!
    $email: String!
    $policy_id: Int!
    $customer_id: String!
  ) {
    createTechUser(
      input: {
        username: $username
        email: $email
        policy_id: $policy_id
        customer_id: $customer_id
      }
    ) {
      username
      email
      _state
      policy_id
    }
  }
`;

export const updateTechUser = /* GraphQL */ `
  mutation updateTechUser(
    $username: String!
    $email: String!
    $policy_id: Int!
  ) {
    updateTechUser(
      input: { username: $username, email: $email, policy_id: $policy_id }
    ) {
      username
      _state
    }
  }
`;

export const deleteTechUser = /* GraphQL */ `
  mutation deleteTechUser($username: String!) {
    deleteTechUser(input: { username: $username }) {
      username
      _state
    }
  }
`;

export const changeTechUserState = /* GraphQL */ `
  mutation changeTechUserState($username: String!, $state: Boolean!) {
    changeTechUserState(input: { username: $username, state: $state }) {
      username
      _state
    }
  }
`;

// -------------------- POLICY ------------------------
export const newPolicy = /* GraphQL */ `
  mutation newPolicy($policy_name: String!, $policy_description: String) {
    createPolicy(
      input: {
        policy_name: $policy_name
        policy_description: $policy_description
      }
    ) {
      policy_id
      policy_name
      policy_description
    }
  }
`;

export const updatePolicy = /* GraphQL */ `
  mutation updatePolicy(
    $policy_id: Int!
    $policy_name: String!
    $policy_description: String
  ) {
    updatePolicy(
      input: {
        policy_id: $policy_id
        policy_name: $policy_name
        policy_description: $policy_description
      }
    ) {
      policy_id
      policy_name
      policy_description
    }
  }
`;

export const deletePolicy = /* GraphQL */ `
  mutation deletePolicy($policy_id: Int!) {
    deletePolicy(input: { policy_id: $policy_id }) {
      policy_id
      policy_name
      policy_description
    }
  }
`;

// -------------------- WORKSPACE ------------------------
export const newWorkspace = /* GraphQL */ `
  mutation newWorkspace(
    $workspace_name: String!
    $n_calls: Int!
    $start_time: String!
    $stop_time: String!
    $date_type: String!
    $policy_id: Int!
  ) {
    createWorkspace(
      input: {
        workspace_name: $workspace_name
        n_calls: $n_calls
        start_time: $start_time
        stop_time: $stop_time
        date_type: $date_type
        policy_id: $policy_id
      }
    ) {
      workspace_name
      n_calls
      start_time
      stop_time
      date_type
      policy_id
    }
  }
`;

export const updateWorkspace = /* GraphQL */ `
  mutation updateWorkspace(
    $workspace_name: String!
    $n_calls: Int!
    $start_time: String!
    $stop_time: String!
    $date_type: String!
    $policy_id: Int!
  ) {
    updateWorkspace(
      input: {
        workspace_name: $workspace_name
        n_calls: $n_calls
        start_time: $start_time
        stop_time: $stop_time
        date_type: $date_type
        policy_id: $policy_id
      }
    ) {
      workspace_name
      n_calls
      start_time
      stop_time
      date_type
      policy_id
    }
  }
`;

export const deleteWorkspace = /* GraphQL */ `
  mutation deleteWorkspace($workspace_name: String!, $policy_id: Int!) {
    deleteWorkspace(
      input: { workspace_name: $workspace_name, policy_id: $policy_id }
    ) {
      workspace_name
      policy_id
    }
  }
`;

// ----------------- LAYER ------------------

export const createLayer = /* GraphQL */ `
  mutation createLayer(
    $workspace_name: String!
    $policy_id: Int!
    $layer_name: String!
  ) {
    createLayer(
      input: {
        workspace_name: $workspace_name
        policy_id: $policy_id
        layer_name: $layer_name
      }
    ) {
      workspace_name
      policy_id
      layer_name
    }
  }
`;

export const deleteLayer = /* GraphQL */ `
  mutation deleteLayer(
    $workspace_name: String!
    $policy_id: Int!
    $layer_name: String!
  ) {
    deleteLayer(
      input: {
        workspace_name: $workspace_name
        policy_id: $policy_id
        layer_name: $layer_name
      }
    ) {
      workspace_name
      policy_id
      layer_name
    }
  }
`;

// ----------------- WS-DOMAIN ------------------

export const addDomain = /* GraphQL */ `
  mutation addDomain(
    $workspace_name: String!
    $policy_id: Int!
    $domain_id: Int!
  ) {
    addDomainToWS(
      input: {
        workspace_name: $workspace_name
        policy_id: $policy_id
        domain_id: $domain_id
      }
    ) {
      domain_id
      domain_name
    }
  }
`;

export const deleteDomainWS = /* GraphQL */ `
  mutation deleteDomainWS(
    $workspace_name: String!
    $policy_id: Int!
    $domain_id: Int!
  ) {
    deleteDomainFromWS(
      input: {
        workspace_name: $workspace_name
        policy_id: $policy_id
        domain_id: $domain_id
      }
    ) {
      workspace_name
      domain_id
    }
  }
`;

// ---------------------- DOMAIN ---------------------
export const newDomain = /* GraphQL */ `
  mutation newDomain(
    $domain_id: Int!
    $domain_name: String!
    $domain_description: String
    $polygon: String!
  ) {
    createDomain(
      input: {
        domain_id: $domain_id
        domain_name: $domain_name
        domain_description: $domain_description
        polygon: $polygon
      }
    ) {
      domain_id
      domain_name
      domain_description
      polygon
    }
  }
`;

export const updateDomain = /* GraphQL */ `
  mutation updateDomain(
    $domain_id: Int!
    $domain_name: String!
    $domain_description: String
    $polygon: String!
  ) {
    updateDomain(
      input: {
        domain_id: $domain_id
        domain_name: $domain_name
        domain_description: $domain_description
        polygon: $polygon
      }
    ) {
      domain_id
      domain_name
      domain_description
      polygon
    }
  }
`;

export const deleteDomain = /* GraphQL */ `
  mutation deleteDomain($domain_id: Int!) {
    deleteDomain(input: { domain_id: $domain_id }) {
      domain_id
      domain_name
      domain_description
      polygon
    }
  }
`;

// ---------------------- TICKETING SERVICE ---------------------
export const createTicketingRequest = /* GraphQL */ `
  mutation createTicketingRequest($input: TicketingRequestInput!) {
    createTicketingRequest(input: $input) {
      service_id
    }
  }
`;

export const linkLocationToTicketingRequest = /* GraphQL */ `
  mutation linkLocationToTicketingRequest($input: [LinkLocationToTicketingRequestInput]!) {
    linkLocationToTicketingRequest(input: $input) {
      service_id
    }
  }
`;

export const deleteTicketingRequest = /* GraphQL */ `
  mutation deleteTicketingRequest($input: DeleteTicketingRequestInput!) {
    deleteTicketingRequest(input: $input) {
      service_id
    }
  }
`;

export const linkDocumentS3ToTicketingRequest = /* GraphQL */ `
  mutation linkDocumentS3ToTicketingRequest($input: [LinkDocumentS3ToTicketingRequestInput]!) {
    linkDocumentS3ToTicketingRequest(input: $input) {
      service_id
    }
  }
`;

export const deleteDocumentS3 = /* GraphQL */ `
  mutation deleteDocumentS3($document_s3_id: String!) {
    deleteDocumentS3(document_s3_id: $document_s3_id) {
      service_id
    }
  }
`;

export const editTicketingRequest = /* GraphQL */ `
  mutation editTicketingRequest($input: TicketingRequestInput!) {
    editTicketingRequest(input: $input) {
      service_id
    }
  }
`;
// ---------------------- GLOBAL LAYERS ---------------------

export const createGlobalLayer = /* GraphQL */ `
  mutation createGlobalLayer($input: GlobalLayerInput!) {
    createGlobalLayer(input: $input) {
      global_layer_id
      layer_name
      layer_description
      layer_polygon
    }
  }
`;

export const editGlobalLayer = /* GraphQL */ `
  mutation editGlobalLayer($input: GlobalLayerInput!) {
    editGlobalLayer(input: $input) {
      global_layer_id
      layer_name
      layer_description
      layer_polygon
    }
  }
`;

export const deleteCustomerLayer = /* GraphQL */ `
  mutation deleteCustomerLayer($input: DeleteGlobalLayerInput!) {
    deleteCustomerLayer(input: $input) {
      service_id
    }
  }
`;
