<template>
  <div>
    <v-flex
      offset-2
      offset-lg1
      offset-xl1
      class="flex-margin"
    >
      <customer-layer-table
        @createLayerEvent="createLayerEvent"
        @editLayerEvent="editLayerEvent($event)"
      />

      <create-layer
        v-if="showCreateLayer"
        class="mt-4"
        :layer-form="layerForm"
        :render-file-map="renderFileMap"
        @closeCreate="showCreateLayer = false"
      />
    </v-flex>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

export default {
  components: {
    CustomerLayerTable: () => import('@/components/settings/CustomerLayerTable'),
    CreateLayer: () => import('@/components/settings/CreateLayer')
  },
  data() {
    return {
      showCreateLayer: false,
      renderFileMap: false,
      layerForm: {}
    };
  },
  computed: {
    ...mapState(['global_layers', 'file_transfer', 'customer']),
    target() {
      const value = 9999;

      return value;
    },
    options() {
      return {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic'
      };
    }
  },
  methods: {
    ...mapActions([]),
    createLayerEvent() {
      this.showCreateLayer = false;
      this.$nextTick(() => {
        this.showCreateLayer = true;
      });

      this.global_layers.isEditing = false;
      this.global_layers.isChangingFile = false;
      this.renderFileMap = false;
      const id = uuidv4();
      this.layerForm = {
        global_layer_id: id,
        layer_name: '',
        layer_description: '',
        layer_polygon: ''
      };
      this.$vuetify.goTo(this.target, this.options);
    },
    async editLayerEvent(item) {
      this.showCreateLayer = false;
      this.$nextTick(() => {
        this.showCreateLayer = true;
      });

      if (this.global_layers.vector != null || this.global_layers.vector != undefined) {
        this.global_layers.map.removeLayer(this.global_layers.vector);
      }

      this.global_layers.isTranslating = false;
      this.global_layers.isEditing = true;
      this.global_layers.isChangingFile = false;
      this.renderFileMap = true;
      this.global_layers.storeLayerForm = item;
      this.global_layers.fileChoice = item.layer_type;
      this.layerForm = JSON.parse(JSON.stringify(item));

      this.$vuetify.goTo(this.target, this.options);
    },
    mobileBreakpoints() {
      return this.$vuetify.breakpoint.name === 'xs';
    }
  }
};
</script>

<style scoped>
.flex-margin{
  margin-right: 2%;
  margin-top: 2%
}
</style>
