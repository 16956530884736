<template>
  <div>
    <div
      v-if="!mobileBreakpoints()"
      app
      class="elevation-0 ma-3 d-block top-component-class"
    >
      <v-btn
        color="RMprimary"
        class="pa-0 ml-1"
        min-height="50"
        min-width="50"
        :disabled="drawer.disableWorkspaceSidebar"
        @click="openWorkspaceSidebar()"
      >
        <SvgIcon
          :rotate="'90'"
          :color1="'#000000'"
          :color2="'#FFFFFF'"
          :title="$t('ServiceCard.services')"
          :path="servicesMenuSvg"
        />
      </v-btn>
    </div>
    <mobile-menu-button v-else />
    <!--Drawer-->
    <div
      v-if="!mobileBreakpoints()"
      id="workspaceSidebar"
      :style="workspaceSidebarDisplay"
    >
      <div
        class="titleBar pa-4 d-flex justify-space-between hidden-xs-only"
      >
        {{ $t(`${getRouteIdTranslation($route)}`) }}
        <v-icon
          color="white"
          @click="drawer.workspaceSidebar = !drawer.workspaceSidebar"
        >
          mdi-arrow-collapse-right
        </v-icon>
      </div>
      <v-col class="RMprimary-background containerServices">
        <span v-if="$route.path.includes('alert-ex-post')">
          <AlertExPostSection />
        </span>
        <span v-else-if="$route.params.id && !$route.path.includes('alert-ex-post')">
          <ServicesSection :specific-default-service="map.specificDefaultService[$route.params.id.replace('-', '')]" />
        </span>
      </v-col>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/utils/SvgIcon.vue';
import ServicesSection from '@/components/core/ServicesSection.vue';
import AlertExPostSection from '@/components/core/AlertExPostSection.vue';
import { mapState } from 'vuex';
import { svgServiceMenu } from '@/svg.js';

export default {
  components: {
    SvgIcon,
    ServicesSection,
    AlertExPostSection,
    MobileMenuButton: () => import('@/components/layout/MobileMenuButton.vue')
  },
  data: () => ({
    servicesMenuSvg: svgServiceMenu
  }),
  computed: {
    ...mapState(['drawer', 'map']),
    workspaceSidebarDisplay() {
      return {
        display: this.drawer.workspaceSidebar ? 'flex' : 'none'
      };
    }
  },
  mounted() {
  },
  methods: {
    mobileBreakpoints() {
      return this.$vuetify.breakpoint.name === 'xs';
    },
    getRouteIdTranslation(route) {
      if (route.params.id) {
        switch (route.params.id) {
          case 'Archive':
            return 'NavigationDrawer.archive';
          case 'Forecast':
            return 'NavigationDrawer.prevision';
          case 'Real-Time':
            return 'NavigationDrawer.real_time';
          default:
            return '';
        }
      } else {
        if (route.path === '/alert-ex-post') {
          return 'NavigationSideBar.alertExPost';
        }
      }
    },
    async openWorkspaceSidebar() {
      this.drawer.workspaceSidebar = !this.drawer.workspaceSidebar;

      if (this.drawer.workspaceSidebar) {
        this.drawer.drawerVisibility = false;
      }

      switch (this.$route.params.id) {
        case 'Archive':
          this.drawer.drawerTitle = 'NavigationDrawer.archive';
          break;
        case 'Real-Time':
          this.drawer.drawerTitle = 'NavigationDrawer.real_time';
          break;
        case 'Forecast':
          this.drawer.drawerTitle = 'NavigationDrawer.prevision';
          break;
        default:
          this.drawer.drawerTitle = '';
      }
    }
  }
};
</script>

<style scoped>
.custom-chip {
  min-height: 50px;
}
.icon-description {
  color: white;
}
.top-component-class {
  position: fixed;
  right: 0;
  z-index: 4;
}
.RMprimary-background {
  background-color: rgb(122, 212, 156, .90);
}
.titleBar {
  background-color: rgb(0, 166, 68, 1);
  max-height: 64px;
  height: 64px;
  color: white;
  font-size: 24px;
  font-weight: 500;
  pointer-events: auto;
}
.iconCloseMenu {
  font-size: 28px;
  cursor: pointer;
  margin-top: -12px;
}
#workspaceSidebar {
  display: flex;
  flex-direction: column;
  z-index: 5;
  position: fixed;
  right: 0;
  width: 32%;
  max-width: 32%;
  height: 100%;
}
.containerServices {
  pointer-events: auto;
  max-height: calc(100% - 64px);
  height: calc(100% - 64px);
}

@media (min-width: 1000px) and (max-width: 1200px) {
  #workspaceSidebar {
    width: 40%;
    max-width: 40%;
  }
}

@media (min-width: 750px) and (max-width: 999px) {
  #workspaceSidebar {
    width: 50%;
    max-width: 50%;
  }
}

@media (min-width: 600px) and (max-width: 749px) {
  #workspaceSidebar {
    width: 60%;
    max-width: 60%;
  }
}
</style>
