import { fromLonLat } from 'ol/proj';

export const log = require('log-util');

const BASE_URLS = {
  dev: 'https://adx9n3xgpl.execute-api.eu-central-1.amazonaws.com/geoserver',
  ppr: 'https://67bsqefyb0.execute-api.eu-central-1.amazonaws.com/geoserver',
  prod: 'https://nzcwqv63j4.execute-api.eu-central-1.amazonaws.com/geoserver'
};

const RASTER_BASE_URLS = {
  dev: 'https://i8p7kxdr02.execute-api.eu-central-1.amazonaws.com/dev',
  ppr: 'https://api-ppr.hypermeteo.com/b2b-binary-ppr',
  prod: 'https://api.hypermeteo.com/b2b-binary'
};

const VECTOR_BASE_URLS = {
  dev: 'https://a7l5cbd8el.execute-api.eu-central-1.amazonaws.com/dev',
  ppr: 'https://api-ppr.hypermeteo.com/b2b-ppr',
  prod: 'https://api.hypermeteo.com/b2b'
};

const ALERTEXPOST_BASE_URLS = {
  dev: 'https://ped9s7h0qi.execute-api.eu-central-1.amazonaws.com/dev',
  ppr: 'https://api-ppr.hypermeteo.com/utility-alert-ppr',
  prod: 'https://api.hypermeteo.com/utility-alert'
};

const REPORTS_BASE_URLS = {
  dev: 'https://z0ftkjec2m.execute-api.eu-central-1.amazonaws.com/dev',
  ppr: 'https://api-ppr.hypermeteo.com/utility-reports-ppr',
  prod: 'https://api.hypermeteo.com/utility-reports'
};

const GA_CODE_OBJ = {
  dev: '',
  ppr: 'G-ZYT55F7Q0P',
  prod: 'G-VPFHFFYQFQ'
};

const MAPBOX_TOKENS = {
  dev: 'pk.eyJ1IjoiYWNoaW5pIiwiYSI6ImNsajhqb214NzB0eW8zbm9ia3JvejVpemsifQ.ZnO-ZiZuVC7MGGEeQ_sz7Q',
  ppr: 'pk.eyJ1IjoiYWNoaW5pIiwiYSI6ImNsajhqb214NzB0eW8zbm9ia3JvejVpemsifQ.ZnO-ZiZuVC7MGGEeQ_sz7Q',
  prod: 'pk.eyJ1IjoicmFkYXJtZXRlbyIsImEiOiJjbGpjeGt2eXAyZGl1M2VxbjZmeTg4NW91In0.3EmW3z2VLg6M7XX7kVm_XQ'
};

export const getErrorMessage = err => {
  log.info(err);

  if (err) {
    if (err.message) {
      if (err.message.includes('length')) {
        return 'This element is empty';
      } else {
        if (
          err.message != null &&
          err.message != '' &&
          err.message != undefined
        ) {
          return err.message;
        }
      }
    } else {
      if (err.errors && err.errors[0] && err.errors[0].message) {
        if (err.errors[0].message.includes('RDS')) {
          const tmp = err.errors[0].message.split(':');
          tmp.shift();
          const error_message = tmp.join(':');

          return JSON.parse(error_message).message;
        } else {
          return err.errors[0].message;
        }
      }
    }
  }
};

export const STAGE = process.env.VUE_APP_STAGE || 'dev';

export const BASE_URL = BASE_URLS[STAGE];

export const RASTER_BASE_URL = RASTER_BASE_URLS[STAGE];

export const VECTOR_BASE_URL = VECTOR_BASE_URLS[STAGE];

export const ALERTEXPOST_BASE_URL = ALERTEXPOST_BASE_URLS[STAGE];

export const REPORTS_BASE_URL = REPORTS_BASE_URLS[STAGE];

export const LANGUAGES = ['en', 'it'];

export const S3_BUCKETS = {
  application: () => (STAGE === 'prod') ? 'meteocast-application-prd' : `meteocast-application-${STAGE}`,
  global_layer: () => (STAGE === 'prod') ? 'meteocast-global-layer-prd' : `meteocast-global-layer-${STAGE}`,
  customer: () => (STAGE === 'prod') ? 'meteocast-customer-prd' : `meteocast-customer-${STAGE}`
};

export const GA_CODE = GA_CODE_OBJ[STAGE];

export const MAPBOX_TOKEN = MAPBOX_TOKENS[STAGE];

export const MAPBOX_STYLE = {
  classic: STAGE === 'prod' ? 'radarmeteo/clmtakgib02j901r77mb45ljk' : 'achini/clmswkcv900j001qn6eova632',
  mountain: STAGE === 'prod' ? 'radarmeteo/clmtapfyf00lx01qnbpxx672x' : 'achini/clmt20vrs02e601r4etsag59s',
  satellite: STAGE === 'prod' ? 'radarmeteo/clmtaq4xn02hq01qx1yla9gw7' : 'achini/clmsy1yi002e401pf6ekw9p1m',
  light: STAGE === 'prod' ? 'radarmeteo/clmtan0ig02gn01pf87ijhzm4' : 'achini/clmt62ifk02ce01nzhad30t3n',
  grey: STAGE === 'prod' ? 'radarmeteo/' : 'achini/cm1us640w016i01r2f2s61h8d',
  dark: STAGE === 'prod' ? 'radarmeteo/clmtam7zs02jd01r74317ekvp' : 'achini/clmt6nru302ea01pb5c73bysd'
};

export const MAP_STYLES = [
  { text: 'Classic', value: 'classic' },
  { text: 'Mountain', value: 'mountain' },
  { text: 'Satellite', value: 'satellite' },
  { text: 'Light', value: 'light' },
  { text: 'Grey', value: 'grey' },
  { text: 'Dark', value: 'dark' }
];

export const requestStates = ['SENT', 'TAKING CHARGE', 'DELETED', 'EMITTED'];

export const transform = (extent) => {
  return fromLonLat(extent, 'EPSG:4326', 'EPSG:3857');
};

log.info(STAGE);

export const METEOGRAM_LAYERS = ['TC2M_HOURLY', 'SKINTEMP_HOURLY', 'TDC2M_HOURLY', 'PREC_HOURLY', 'SNOW_HOURLY', 'PREC_CUM_HOURLY', 'SNOW_CUM_HOURLY', 'PREC_PROB_HOURLY', 'HAILPROB_HOURLY', 'WDIR10M_HOURLY', 'WSPD10M_HOURLY', 'WSPDX10M_HOURLY', 'VISIBILITY_HOURLY', 'LEAFWET_HOURLY', 'POTEVP_ACC_HOURLY', 'FROST_PROB_HOURLY', 'SOFTRIME_PROB_HOURLY', 'FRZRAIN_HOURLY'];

export const METEOGRAM_CONFIG = {
  lineType: ['TC2M_HOURLY', 'SKINTEMP_HOURLY', 'TDC2M_HOURLY', 'WSPD10M_HOURLY', 'WSPDX10M_HOURLY'],
  areaType: ['PREC_CUM_HOURLY', 'SNOW_CUM_HOURLY', 'POTEVP_ACC_HOURLY'],
  rightYAxis: ['PREC_CUM_HOURLY', 'SNOW_CUM_HOURLY', 'LEAFWET_HOURLY'],
  windDirectionLayer: ['WDIR10M_HOURLY'],
  chartVariables: {
    meteogram1: {
      variable: ['TC2M_HOURLY', 'SKINTEMP_HOURLY', 'TDC2M_HOURLY'],
      minMaxLeft: [0, 30],
      minMaxRight: [],
      labelLeft: [],
      labelRight: []
    },
    meteogram2: {
      variable: ['PREC_HOURLY', 'SNOW_HOURLY', 'PREC_CUM_HOURLY', 'SNOW_CUM_HOURLY', 'FRZRAIN_HOURLY'],
      minMaxLeft: [0, 10],
      minMaxRight: [0, 100],
      labelLeft: ['Meteogram2.yLabelLeftDouble'],
      labelRight: ['Meteogram2.yLabelRight', 'Meteogram2.yLabelRightDouble']
    },
    meteogram3: {
      variable: ['PREC_PROB_HOURLY', 'HAILPROB_HOURLY'],
      minMaxLeft: [0, 100],
      minMaxRight: [],
      labelLeft: [],
      labelRight: []
    },
    meteogram7: {
      variable: ['FROST_PROB_HOURLY', 'SOFTRIME_PROB_HOURLY'],
      minMaxLeft: [0, 100],
      minMaxRight: [],
      labelLeft: [],
      labelRight: []
    },
    meteogram4: {
      variable: ['WSPD10M_HOURLY', 'WSPDX10M_HOURLY'],
      minMaxLeft: [0, 20],
      minMaxRight: [],
      labelLeft: [],
      labelRight: []
    },
    meteogram5: {
      variable: ['VISIBILITY_HOURLY'],
      minMaxLeft: [0, 5000],
      minMaxRight: [],
      labelLeft: [],
      labelRight: []
    },
    meteogram6: {
      variable: ['LEAFWET_HOURLY', 'POTEVP_ACC_HOURLY'],
      minMaxLeft: [0, 30],
      minMaxRight: [0, 1],
      labelLeft: [],
      labelRight: ['Meteogram6.yLabelRight']
    }
  }
};
