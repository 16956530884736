<template>
  <div>
    <v-flex
      offset-2
      offset-lg-1
      class="flex-margin"
    >
      <v-card
        flat
        color="transparent"
      >
        <v-card-title class="main-title pr-0">
          <span>Manuali</span>
          <v-spacer />
          <v-icon
            v-if="mobileBreakpoints()"
            class="text-right"
            color="red"
            size="28"
            @click="$router.push('/')"
          >
            mdi-close
          </v-icon>
        </v-card-title>


        <v-card
          v-for="(manual, index) in app_user.customerManuals"
          :key="index"
          class="mt-5"
        >
          <v-card-title>
            <v-row
              class="mt-1"
              style="cursor: pointer"
              @click="downloadManual(manual.manual_s3_path)"
            >
              <v-col
                cols="12"
                md="4"
                class="title-color pb-0 pt-0"
              >
                {{ $t(manual.label_code) }}
              </v-col>
              <v-col
                cols="12"
                md="6"
                class="title-color pb-0 pt-0"
              >
                <span class="children-color description-size">
                  {{ manual.manual_s3_path.split("/manuals/")[1] }}
                </span>
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="pb-0 pt-0"
              >
                <SvgIcon
                  :color="'#0ca344'"
                  :color1="'#0ca344'"
                  :color2="'#0ca344'"
                  :title="manual.manual_s3_path.split('/manuals/')[1]"
                  :path="toLinkSvg"
                />
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-card>
    </v-flex>
  </div>
</template>

<script>
import SvgIcon from '@/components/utils/SvgIcon.vue';
import { S3_BUCKETS } from '@/constants.js';
import AWS from 'aws-sdk';
import { mapActions, mapState } from 'vuex';
import { svgToLink } from '@/svg.js';

export default {
  components: {
    SvgIcon
  },
  props: {
  },
  data() {
    return {
      toLinkSvg: svgToLink
    };
  },
  computed: {
    ...mapState(['file_transfer', 'app_user'])
  },
  methods: {
    ...mapActions([]),
    async downloadManual(path) {
      const params = {
        Bucket: S3_BUCKETS.customer(),
        Key: path
      };
      const s3_bucket = new AWS.S3();
      const response = await s3_bucket.getSignedUrlPromise('getObject', params);

      const link = document.createElement('a');
      link.href = response;
      link.target = '_blank';
      link.click();
    },
    mobileBreakpoints() {
      return this.$vuetify.breakpoint.name === 'xs';
    }
  }
};
</script>

<style scoped>
.flex-margin{
  margin-right: 2%;
  margin-top: 2%
}
.title-color {
  color: #0ca344;
}
.main-title {
  color: #0ca344;
}
.v-expansion-panel::before {
  box-shadow: none;
}
@media (max-device-width:1023px) {
  .title-color {
    font-size: 1rem !important;
  }
  .description-size{
    font-size: 13px !important;
  }
}
.children-color {
  font-size: 18px;
  color: #3d3d3d;
}
</style>
