<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col
        style="max-width: 500px"
        cols="10"
        sm="8"
        md="6"
        class="pb-6"
      >
        <v-card
          :class="{'pa-8': !mobileBreakpoints, 'pa-2': mobileBreakpoints}"
          class="elevation-12 card"
        >
          <v-card-title :class="{'mb-8': !mobileBreakpoints, 'mb-2; cardTitle': mobileBreakpoints}">
            {{ $t(pageTitle) }}
          </v-card-title>
          <v-alert
            v-if="message"
            :type="alertType"
            dismissible
            @click="message = ''"
          >
            {{ message }}
          </v-alert>
          <v-card-text v-if="type === 'login'">
            <v-form>
              <v-label>Username*</v-label>
              <v-text-field
                v-model="username"
                class="mt-2"
                :placeholder="$t('Signin.writeYourUsername')"
                outlined
                requied
                @keyup.enter="handleEnterKey(type)"
                @keydown="removeSpaces"
                @paste="handlePaste"
                @input="handleInput"
              />
              <v-label>Password*</v-label>
              <v-text-field
                v-model="password"
                class="mt-2"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                :placeholder="$t('Signin.writeYourPassword')"
                outlined
                requied
                hide-details
                @click:append="togglePasswordVisibility"
                @keyup.enter="handleEnterKey(type)"
              />
              <p class="mb-8 mt-3">
                <span
                  class="link"
                  @click="forgotPassword"
                >
                  {{ $t('Signin.forgotPassword') }}
                </span>
              </p>
              <v-row>
                <v-spacer />
                <v-btn
                  class="ma-3 px-8"
                  color="#00a644"
                  :dark="!logInDisabled"
                  :disabled="logInDisabled"
                  @click="signIn"
                >
                  {{ $t('Signin.signIn') }}
                </v-btn>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-text v-if="type === 'forgot'">
            <v-form>
              <v-label>Username*</v-label>
              <v-text-field
                v-model="username"
                class="mt-2"
                :placeholder="$t('Signin.writeYourUsername')"
                outlined
                requied
                hide-details
                @keyup.enter="handleEnterKey(type)"
                @keydown="removeSpaces"
                @paste="handlePaste"
                @input="handleInput"
              />
              <p class="mb-8 mt-3">
                <span
                  class="link"
                  @click="init"
                >
                  {{ $t('Signin.backToSignIn') }}
                </span>
              </p>
              <v-row>
                <v-spacer />
                <v-btn
                  class="ma-3 px-8"
                  color="#00a644"
                  :dark="!resetPasswordDisabled"
                  :disabled="resetPasswordDisabled"
                  @click="resetPassword"
                >
                  {{ $t('Signin.sendCode') }}
                </v-btn>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-text v-if="type === 'reset'">
            <v-form>
              <v-label>{{ $t('Signin.verificationCode') }}</v-label>
              <v-text-field
                v-model="verificationCode"
                class="mt-2"
                type="number"
                :placeholder="$t('Signin.enterCode')"
                outlined
                requied
                hide-spin-buttons
                @keyup.enter="handleEnterKey(type)"
              />
              <v-label>{{ $t('Signin.newPassword') }}</v-label>
              <v-text-field
                v-model="newPassword"
                class="mt-2"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                :placeholder="$t('Signin.writeYourNewPassword')"
                outlined
                requied
                @click:append="togglePasswordVisibility"
                @keyup.enter="handleEnterKey(type)"
              />
              <v-label>{{ $t('Signin.confirmPassword') }}</v-label>
              <v-text-field
                v-model="confirmPassword"
                class="mt-2"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                :placeholder="$t('Signin.confirmYourNewPassword')"
                outlined
                requied
                hide-details
                @click:append="togglePasswordVisibility"
                @keyup.enter="handleEnterKey(type)"
              />
              <p class="mb-8 mt-3">
                <span
                  class="link"
                  @click="init"
                >
                  {{ $t('Signin.backToSignIn') }}
                </span>
              </p>
              <v-row>
                <v-spacer />
                <v-btn
                  class="ma-3 px-8"
                  color="#00a644"
                  :dark="!changePasswordDisabled"
                  :disabled="changePasswordDisabled"
                  @click="submitResetPassword"
                >
                  {{ $t('Signin.submit') }}
                </v-btn>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-text v-if="type === 'new'">
            <v-form>
              <v-label>{{ $t('Signin.newPassword') }}</v-label>
              <v-text-field
                v-model="newPassword"
                class="mt-2"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                :placeholder="$t('Signin.writeYourNewPassword')"
                outlined
                requied
                @click:append="togglePasswordVisibility"
                @keyup.enter="handleEnterKey(type)"
              />
              <v-label>{{ $t('Signin.confirmPassword') }}</v-label>
              <v-text-field
                v-model="confirmPassword"
                class="mt-2"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                :placeholder="$t('Signin.confirmYourNewPassword')"
                outlined
                requied
                hide-details
                @click:append="togglePasswordVisibility"
                @keyup.enter="handleEnterKey(type)"
              />
              <p class="mb-8 mt-3">
                <span
                  class="link"
                  @click="init"
                >
                  {{ $t('Signin.backToSignIn') }}
                </span>
              </p>
              <v-row>
                <v-spacer />
                <v-btn
                  class="ma-3 px-8"
                  color="#00a644"
                  :dark="!forceChangePasswordDisabled"
                  :disabled="forceChangePasswordDisabled"
                  @click="forceChangePassword"
                >
                  OK
                </v-btn>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Auth } from 'aws-amplify';
// import * as api from '@/api/api.js';

export default {
  props: {},
  data() {
    return {
      pageTitle: 'Signin.title',
      message: '',
      alertType: 'error',
      type: 'login',
      user: null,
      username: null,
      password: null,
      verificationCode: null,
      confirmPassword: null,
      newPassword: null,
      showPassword: false
    };
  },
  computed: {
    logInDisabled() {
      return this.username === '' || this.password === '' || this.username === null || this.password === null;
    },
    resetPasswordDisabled() {
      return this.username === '' || this.username === null;
    },
    changePasswordDisabled() {
      return this.verificationCode === '' || this.newPassword === '' || this.confirmPassword === '' || this.verificationCode === null || this.newPassword === null || this.confirmPassword === null;
    },
    forceChangePasswordDisabled() {
      return this.newPassword === '' || this.confirmPassword === '' || this.newPassword === null || this.confirmPassword === null;
    }
  },
  mounted () {},
  methods: {
    mobileBreakpoints() {
      return this.$vuetify.breakpoint.name === 'xs';
    },
    init() {
      this.pageTitle = 'Signin.title';
      this.showPassword = false;
      this.verificationCode = null;
      this.confirmPassword = null;
      this.newPassword = null;
      this.alertType = 'error';
      this.type = 'login';
      this.message = '';
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    async signIn() {
      this.message = '';
      Auth.signIn(this.username, this.password)
        .then(async authData => {
          this.user = authData;

          if (authData.challengeName === 'NEW_PASSWORD_REQUIRED') {
            this.pageTitle = 'Signin.changePassword';
            this.showPassword = false;
            this.type = 'new';
          } else {
            this.$emit('log-in', authData);
            this.$emit('is-authenticated', true);
            this.init();
          }
        })
        .catch((error) => {
          this.$emit('is-authenticated', false);
          this.errorMessage(error.message);
        });
    },
    forgotPassword() {
      this.message = '';
      this.pageTitle = 'Signin.resetPassword';
      this.showPassword = false;
      this.type = 'forgot';
    },
    async resetPassword() {
      try {
        this.message = '';
        await Auth.forgotPassword(this.username);
        this.type = 'reset';
        this.alertType = 'success';
        this.message = this.$t('Signin.instructions');
      } catch (error) {
        this.errorMessage(error.message);
      }
    },
    async submitResetPassword() {
      if (this.newPassword !== this.confirmPassword) {
        this.errorMessage(this.$t('Signin.notMatch'));

        return;
      }

      try {
        this.message = '';
        await Auth.forgotPasswordSubmit(this.username, this.verificationCode, this.newPassword);
        this.init();
      } catch (error) {
        this.errorMessage(error.message);
      }
    },
    async forceChangePassword() {
      if (this.newPassword !== this.confirmPassword) {
        this.errorMessage(this.$t('Signin.notMatch'));

        return;
      }

      this.message = '';
      Auth.completeNewPassword(this.user, this.newPassword)
        .then(async () => {
          // 'Password reset successfully. You can now sign in with your new password.'
          this.password = this.newPassword;
          this.showPassword = false;
          await this.signIn();
        })
        .catch((error) => {
          this.errorMessage(error.message);
        });
    },
    errorMessage(error) {
      this.alertType = 'error';
      this.message = error;
    },
    handleEnterKey(type) {
      if (type === 'login' && !this.logInDisabled) {
        this.signIn();
      }

      if (type === 'forgot' && !this.resetPasswordDisabled) {
        this.resetPassword();
      }

      if (type === 'reset' && !this.changePasswordDisabled) {
        this.submitResetPassword();
      }

      if (type === 'new' && !this.forceChangePasswordDisabled) {
        this.forceChangePassword();
      }
    },
    removeSpaces(event) {
      if (event.key === ' ') {
        event.preventDefault();
        this.username += '';
      }
    },
    handlePaste(event) {
      event.preventDefault();
      const pastedText = (event.clipboardData || window.clipboardData).getData('text');
      const textWithoutSpaces = pastedText.replace(/\s/g, '');
      this.username = textWithoutSpaces;
    },
    handleInput() {
      this.username = this.username.replace(/\s/g, '');
    }
    // async signOut() {
    //   api.logOff();
    //   this.init();
    //   this.$emit('is-authenticated', false);
    // }
  }
};
</script>

<style>
  .link {
    color: #00a644;
    cursor: pointer;
  }
  .link:hover {
    text-decoration: underline;
  }

  @media (max-width: 600px) {
    .cardTitle {
      font-size: 1rem;
      word-break: break-word;
    }

    .card {
      width: 100%;
      max-width: calc(100vw - 24px);
    }

    .v-input__slot {
      min-height: 48px!important;
    }

    .v-input__slot .v-input__append-inner {
      min-height: inherit;
      margin: 0px;
      align-items: center;
    }
  }
</style>
