var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{staticClass:"flex-margin",attrs:{"offset-2":"","offset-lg-1":""}},[_c('v-card',[_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-card-title',{staticClass:"card-title v-card-scroll",attrs:{"color":"black"}},[_vm._v(" "+_vm._s(_vm.$t(("ServiceCard." + (_vm.$route.params.id) + ".title")))+" "),_c('v-spacer'),(_vm.mobileBreakpoints())?_c('div',{staticClass:"d-flex",staticStyle:{"justify-content":"end"}},[_c('v-icon',{staticClass:"text-right",attrs:{"color":"red","size":"28"},on:{"click":function($event){return _vm.$router.push('/')}}},[_vm._v(" mdi-close ")])],1):_vm._e()],1),_c('v-tabs',{attrs:{"color":"green","active-class":"white","background-color":"#f4f3f3","grow":""},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.reportType),function(type,index){return _c('v-tab',{key:index},[_vm._v(" "+_vm._s(type.label)+" ")])}),1),(_vm.selectedTab > 0)?_c('v-row',{staticClass:"ms-1 my-2 me-3"},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{ref:"filterReportHubMenu",attrs:{"close-on-content-click":false,"return-value":_vm.rangeDate,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.rangeDate=$event},"update:return-value":function($event){_vm.rangeDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"shrink",attrs:{"value":_vm.rangeDateText,"multiple":"","chips":"","small-chips":"","label":"Filter range date","prepend-icon":"mdi-calendar","readonly":"","clearable":"","hide-details":_vm.mobileBreakpoints()},on:{"click:clear":function($event){return _vm.closeDateRange()}}},'v-text-field',attrs,false),on))]}}],null,false,1638321707),model:{value:(_vm.rangeMenu),callback:function ($$v) {_vm.rangeMenu=$$v},expression:"rangeMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","range":"","scrollable":"","min":_vm.rangeDate[0],"max":_vm.moment().format('YYYY-MM-DD')},model:{value:(_vm.rangeDate),callback:function ($$v) {_vm.rangeDate=$$v},expression:"rangeDate"}},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.closeDateRange()}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary","disabled":_vm.rangeDate.length < 2},on:{"click":function($event){return _vm.changeFilterDate()}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{staticClass:"shrink",style:({height: _vm.mobileBreakpoints() ? '50px' : '60px'}),attrs:{"clearable":"","prepend-icon":"mdi-magnify","label":_vm.$t('AlertExPost.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1):_vm._e(),_c('v-tabs-items',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.reportType),function(type,index){return _c('v-tab-item',{key:index},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tabHeaders,"items":_vm.filteredItems,"search":_vm.search,"sort-by":"reportDate","sort-desc":true,"footer-props":{
              itemsPerPageAllText: _vm.$t('Table.all'),
              itemsPerPageText: _vm.$t('Table.rowsPerPage'),
              pageText: ("{0}-{1} " + (_vm.$t('Table.of')) + " {2}")
            },"no-data-text":_vm.$t('Table.noData')},scopedSlots:_vm._u([{key:"item.reportDate",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.reportDate || '-')+" ")]}},{key:"item.view",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{staticClass:"me-3",attrs:{"small":"","outlined":"","color":"success","dark":""},on:{"click":function($event){return _vm.dispatchDownloadItem(item.s3_path)}}},[_vm._v(" PDF ")])]}}],null,true)})],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }