<template>
  <v-row class="pl-1 py-1 d-flex direction">
    <v-btn
      :title="$t('Sidebar.map')"
      :color="mapSwitchActive ? 'RMblack' : 'RMwhite'"
      class="pa-0"
      min-height="40"
      min-width="40"
      max-width="40"
      @click="setMapStyle('')"
    >
      <v-icon :color="mapSwitchActive ? 'grey' : 'black'">
        mdi-map-outline
      </v-icon>
    </v-btn>
    <v-divider
      class="ma-0 pa-0"
      width="40"
    />
    <div
      v-if="mapSwitchActive"
      class="d-flex direction"
    >
      <v-btn
        icon
        :title="$t('Sidebar.classic')"
        :min-height="btn"
        :min-width="btn"
        @click="setMapStyle('classic')"
      >
        <v-img
          :style="imgBorder(classicSelected)"
          src="@/assets/map-classic.png"
          :width="btn"
        />
      </v-btn>
      <v-divider
        class="ma-0 pa-0"
        width="40"
      />
      <v-btn
        icon
        :title="$t('Sidebar.mountain')"
        :min-height="btn"
        :min-width="btn"
        @click="setMapStyle('mountain')"
      >
        <v-img
          :style="imgBorder(mountainSelected)"
          src="@/assets/map-mountain.png"
          :width="btn"
        />
      </v-btn>
      <v-divider
        class="ma-0 pa-0"
        width="40"
      />
      <v-btn
        icon
        :title="$t('Sidebar.satellite')"
        :min-height="btn"
        :min-width="btn"
        @click="setMapStyle('satellite')"
      >
        <v-img
          :style="imgBorder(satelliteSelected)"
          src="@/assets/map-satellite.png"
          :width="btn"
        />
      </v-btn>
      <v-divider
        class="ma-0 pa-0"
        width="40"
      />
      <v-btn
        icon
        :title="$t('Sidebar.light')"
        :min-height="btn"
        :min-width="btn"
        @click="setMapStyle('light')"
      >
        <v-img
          :style="imgBorder(lightSelected)"
          src="@/assets/map-light.png"
          :width="btn"
        />
      </v-btn>
      <v-divider
        class="ma-0 pa-0"
        width="40"
      />
      <v-btn
        icon
        :title="$t('Sidebar.grey')"
        :min-height="btn"
        :min-width="btn"
        @click="setMapStyle('grey')"
      >
        <v-img
          :style="imgBorder(greySelected)"
          src="@/assets/map-grey.png"
          :width="btn"
        />
      </v-btn>
      <v-divider
        class="ma-0 pa-0"
        width="40"
      />
      <v-btn
        icon
        :title="$t('Sidebar.dark')"
        :min-height="btn"
        :min-width="btn"
        @click="setMapStyle('dark')"
      >
        <v-img
          :style="imgBorder(darkSelected)"
          src="@/assets/map-dark.png"
          :width="btn"
        />
      </v-btn>
    </div>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';
import { MAPBOX_STYLE } from '@/constants';

export default {
  props: {
    mapStyle: {
      type: String,
      default: 'classic'
    }
  },
  data: () => ({
    mapSwitchActive: false,
    classicSelected: true,
    mountainSelected: false,
    satelliteSelected: false,
    lightSelected: false,
    greySelected: false,
    darkSelected: false,
    btn: 40
  }),
  computed: {
    ...mapState(['map'])
  },
  watch: {
    $route() {},
    mapStyle: {
      async handler(style) {
        if (this.map.MBmap) {
          this.setMapStyle(style);
          this.mapSwitchActive = false;
        }
      },
      immediate: true
    }
  },
  methods: {
    imgBorder(selected) {
      return `border: 2px solid ${selected ? '#0ca344' : '#000'}; border-radius: 5px;`;
    },
    setMapStyle(mapStyle) {
      if (mapStyle !== '') {
        this.classicSelected = mapStyle === 'classic';
        this.mountainSelected = mapStyle === 'mountain';
        this.satelliteSelected = mapStyle === 'satellite';
        this.lightSelected = mapStyle === 'light';
        this.greySelected = mapStyle === 'grey';
        this.darkSelected = mapStyle === 'dark';

        this.map.MBmap.setStyle(`mapbox://styles/${MAPBOX_STYLE[mapStyle]}`);
      } else {
        this.mapSwitchActive = !this.mapSwitchActive;
      }
    }
  }
};
</script>

<style scoped>
  .direction {
    flex-direction: column;
  }

  @media (max-device-width: 599px) {
    .direction {
      flex-direction: column-reverse!important;
    }
}
</style>
