<template>
  <div>
    <v-flex
      offset-2
      offset-lg1
      offset-xl1
      class="flex-margin"
    >
      <v-card>
        <v-card-title
          class="card-title v-card-scroll"
          color="black"
        >
          {{ $t('NavigationSideBar.registry') }}
          <v-spacer />
          <v-icon
            v-if="mobileBreakpoints()"
            color="red"
            size="28"
            @click="$router.push('/')"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-overlay :value="overlay">
          <v-progress-circular
            indeterminate
            size="64"
          />
        </v-overlay>
        <v-form
          ref="appUserForm"
          v-model="valid"
          class="pa-4"
          lazy-validation
        >
          <v-row class="title mt-4 mb-4">
            {{ $t('NavigationSideBar.personalData') }}
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="app_user.username"
                label="Username"
                required
                :disabled="true"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="app_user.app_user_data[0]._name"
                :rules="name_rules"
                :label=" $t('AppUserForm.name')"
                required
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="app_user.app_user_data[0].surname"
                :rules="name_rules"
                :label="$t('AppUserForm.surname')"
                required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="app_user.app_user_data[0].email"
                label="E-mail"
                required
                :disabled="true"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="app_user.app_user_data[0].phone"
                :rules="phoneRules"
                :label="$t('AppUserForm.phone')"
              />
            </v-col>
          </v-row>
          <v-row class="title mt-4 mb-4">
            {{ $t('NavigationSideBar.settings') }}
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="app_user.app_user_data[0].language"
                :items="items"
                item-value="language"
                item-text="language"
                :label="$t('AppUserForm.language')"
                :rules="language_rules"
                dense
              />
            </v-col>
            <v-col>
              <v-select
                v-model="app_user.app_user_data[0].user_map_style"
                :items="mapStyles"
                :value="mapStyles.value"
                :label="$t('AppUserForm.userMapStyle')"
                :clearable="app_user.app_user_data[0].user_map_style !== ''"
                dense
              />
            </v-col>
          </v-row>
          <v-spacer />
          <div>
            <v-btn
              color="success"
              class="mr-4"
              @click="submit"
            >
              {{ $t('AppUserForm.submit') }}
            </v-btn>
          </div>
        </v-form>
      </v-card>
    </v-flex>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { loadLanguageAsync } from '@/plugins/i18n.js';
import { MAP_STYLES } from '@/constants.js';
import {
  required,
  manageLenghtError,
  getRegExp
} from '@/formRules.js';

export default {
  data() {
    return {
      items: [{
        id: 0,
        language: '' },
      {
        id: 1,
        language: 'IT' },
      {
        id: 2,
        language: 'EN' }

      ],
      mapStyles: [{ text: 'Default', value: '' }, ...MAP_STYLES],
      valid: true,
      overlay: false,
      name_rules: [...required, ...manageLenghtError(255)],
      phone_rules: [...getRegExp('phone_number')],
      language_rules: [...required],
      appUserForm: {
        username: '',
        _name: '',
        surname: '',
        email: '',
        phone: '',
        language: '',
        user_map_style: ''
      }
    };
  },
  computed: {
    ...mapState(['app_user', 'map']),
    phoneRules() {
      if (!this.appUserForm.phone) {
        return [!this.appUserForm.phone];
      } else {
        return this.phone_rules;
      }
    }
  },
  async created() {
    await this.storeAppUser(this.app_user.app_user_id);
  },
  methods: {
    ...mapActions(['storeAppUser', 'storeUpdatedAppUser']),
    async submit() {
      this.overlay = true;
      this.appUserForm.app_user_id = this.app_user.app_user_id;
      this.appUserForm.username = this.app_user.username;
      this.appUserForm._name = this.app_user.app_user_data[0]._name;
      this.appUserForm.surname = this.app_user.app_user_data[0].surname;
      this.appUserForm.email = this.app_user.app_user_data[0].email;
      this.appUserForm.phone = this.app_user.app_user_data[0].phone;
      this.appUserForm.language = this.app_user.app_user_data[0].language;
      this.appUserForm.user_map_style = this.app_user.app_user_data[0].user_map_style;
      await this.storeUpdatedAppUser(this.appUserForm);
      await this.storeAppUser(this.app_user.app_user_id);
      this.map.mapStyle = this.app_user.app_user_data[0].user_map_style || this.app_user.app_user_data[0].map_style;
      await loadLanguageAsync(this.appUserForm.language.toLowerCase());
      this.map.mapLocale = this.appUserForm.language.toLowerCase() === 'it' ? 'Italian' : 'English';
      this.map.MBmap.setLanguage(this.map.mapLocale);
      this.overlay = false;
    },
    mobileBreakpoints() {
      return this.$vuetify.breakpoint.name === 'xs';
    }
  }
};
</script>

<style scoped>
.title-color {
  color: #0ca344;
}
.viewMargin {
  margin-left: 10em !important;
}
.description-color {
  color: #3d3d3d;
}
.flex-margin {
  margin-right: 2%;
  margin-top: 2%
}
@media (max-device-width:600px){
  .form {
    padding-left: 5%;
    padding-right: 5%;
  }
}
.div-width {
  width: 300px;
}
.card-title {
  color: #0da344;
  background-color: #f4f3f3;
  font-size: 22px;
}
.card {
  margin-right:5%;
}
.row {
  margin: 0px!important;
}
</style>
